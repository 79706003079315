.mainBody{
    display: flex;
    padding-bottom: 30px;
    border-bottom: 1px solid rgb(214, 206, 206);
    margin: 10px;
}

.productImages{
    display: flex;
    width: 40%;
    position: sticky;
    align-self: flex-start;
    top: 0;
}
.small-img {
    display: flex;
    flex-direction: column;
}
.small-img div{
    padding: 5px;
}
.small-img img {
    height: 45px;
    width: 45px;
    padding: 3px;
    cursor: pointer;
    display: block;
    border: 1px solid grey;
}
.small-img img:hover{
    border-color: #e77600;
    box-shadow: 0 0 3px 2px rgb(228 121 17 / 50%);
}
.big-img {
    padding: 90px;
    width: 100%;
}
.big-img img{
    width: 90%;
}

.productDescription{
    display: flex;
    flex-direction: column;
    width: 40%;
}
.productName{
    font-size: 1.3em;
    padding-top: 10px;
}
.ratings{
    display: flex;
    align-items: center;
    cursor: pointer;
}
.star{
    height: 15px;
}
.ratings > span{
    padding-left: 20px;
    color: rgb(60, 109, 110);
    font-size: 0.9em;
}
.downArrow{
    height: 0.6em !important;
}
.line{
    padding-top: 5px;
    border-bottom: 1px solid rgb(214, 206, 206);
}
.yearsTable{
    padding-top: 20px;
}
.tableHead{
    text-align: right;
    color: #565959;
    font-size: 14px;
}
.tableValue1{
    color: #565959;
    font-size: 18px;
    text-decoration: line-through;
}
.tableValue{
    color: #B12704;
    font-size: 18px;
}
.available{
    padding: 10px 0 10px 0;
}
.availableDays{
    font-weight: bold;
}
.htTable > tbody > tr > td{
    border-bottom: 1px solid #e7e7e7;
    padding: 10px;
}
.htTable{
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #e7e7e7;
    font-size: 14px;
}
.offerImg{
    height: 25px;
    padding-right: 10px;
}
.keyPoints{
    color: #B12704;
    font-weight: bold;
}
.htHead{
    display: flex;
    align-items: center;
}
.keyHighWebsites{
    color: rgb(60, 109, 110);
    font-size: 0.9em;
}
.specialIcons{
    display: flex;
    color: rgb(60, 109, 110);
    font-size: 13px;
    text-align: center;
}
.iconA{
    padding: 20px;
}
.iconA > img{
    height: 30px;
}
.inStock{
    padding: 30px 0 20px 0;
    color: #058552;
    font-size: 18px;
}
.soldBy > span{
    color: rgb(60, 109, 110);
}
.aboutThis{
    padding-top: 20px;
    font-weight: bold;
}
.extraPoints{
    padding: 5px 20px 20px 20px;
    font-size: 14px;
}
.extraPoints li{
    line-height: 150%;
}
.reportInfo{
    display: flex;
    align-items: center;
    color: rgb(60, 109, 110);
    font-size: 14px;
}
.infoIcon{
    padding: 3px;
}

.productBuy{
    width: 20%;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 0 15px 0 25px;
}
.links{
    display: flex;
    align-items: center;
    padding-bottom: 5px;
}
.linkName{
    color: rgb(60, 109, 110);
    font-size: 14px;
    padding-right: 10px;
}
.linkIcon{
    height: 20px;
    margin: 5px;
    cursor: pointer;
}
.buyBox{
    width: 100%;
    border: 1px solid rgb(214, 206, 206);
    border-radius: 8px;
    padding: 15px;
}
.quantityArea > label{
    padding-right: 5px;
}
#quantity{
    border: 1px solid rgb(214, 206, 206);
    border-radius: 4px;
    padding: 4px;
}
.buttons{
    display: flex;
    flex-direction: column;
    padding-top: 10px;
}
.buttons > input{
    margin: 5px;
    padding: 10px;
    border-radius: 25px;
    border: none;
}
.addBtn{
    background-color: #FFD814;
}
.buyBtn{
    background-color: #FFA41C;
}
.secureTrans{
    display: flex;
    align-items: flex-start;
    padding: 10px;
}
.lock{
    height: 15px;
    padding-right: 10px;
}
.stText, .deliverToPraveenTxt{
    color: rgb(60, 109, 110);
    font-size: 14px;
}
.giftOptn, .deliverToPraveen{
    display: flex;
    align-items: flex-start;
    padding: 5px 10px 10px 15px;
}
.giftBtn{
    margin-right: 5px;
}
.giftOptn > label{
    font-size: 14px;
}
.deliverToPraveenImg{
    height: 15px;
    margin-right: 5px;
}
.wishlistBtn{
    width: 90%;
    padding: 8px;
    border: 1px solid rgb(160, 160, 160);
    margin-top: 15px;
    text-align: left;
    border-radius: 3px 0 0 3px;
    box-shadow: 0 2px 5px 0 rgb(213 217 217 / 50%);
}
.wishlistBtnArrow{
    width: 10%;
    padding: 8px;
    border: 1px solid rgb(160, 160, 160);
    border-left: none;
    margin-top: 15px;
    text-align: center;
    border-radius: 0 3px 3px 0;
    box-shadow: 0 2px 5px 0 rgb(213 217 217 / 50%);
}
