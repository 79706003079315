.freqBoughtArea{
    display: flex;
    padding-bottom: 10px;
    border-bottom: 1px solid rgb(214, 206, 206);
    margin: 15px;
    flex-direction: column;
}
.fbHeading{
    font-size: 25px;
    font-weight: bold;
}
.fbImgaBtn{
    display: flex;
}
.fbImages{
    display: flex;
    align-items: center;
    margin: 20px 0 10px 0;
}
.fbImg{
    height: 90px;
    margin: 0 10px 0 10px;
}
.plus{
    font-size: 25px;
    color: rgb(146, 139, 129);
}
.fbAddtoCart{
    margin: 20px;
    padding: 8px;
    border: none;
    border-radius: 10px;
    background-color: #FFD814;
    cursor: pointer;
}
.fbItemDetail{
    padding: 10px 0 10px 20px;
    font-size: 14px;
}
.fbcbTextBold{
    font-weight: bold;
}
.fbCheckbox{
    margin-right: 5px;
}
.fbcbText{
    color: rgb(60, 109, 110);
    font-size: 14px;
    cursor: pointer;
}