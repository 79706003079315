.haveAqusArea{
    display: flex;
    padding-bottom: 30px;
    border-bottom: 1px solid rgb(214, 206, 206);
    margin: 35px 15px 15px 15px;
    flex-direction: column;
}
.haqHeading{
    font-size: 25px;
    font-weight: bold;
}
.haqText{
    padding: 10px 0 5px 0;
    font-size: 12px;
    color: rgb(146, 139, 129);
}
.haqSearch{
    display: flex;
    margin: 10px 0 10px 0;
    border: 1px solid rgb(158, 151, 151);
    border-radius: 4px;
    padding: 10px;
    width: 50%;
}
.haqSearchIcon{
    height: 12px;
    margin-right: 10px;
}
.haqSearchText{
    border: none;
    width: 100%;
}