.navbar{
    background-color: #131921;
    color: white;
    display: flex;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 100;
  }
.logo{
  display: flex;
  flex-direction: column;
  font-size: 1.9em;
  margin: 0px 0px 10px 15px;
}
.logo img{
  height: 15px;
}
.logo span{
  font-size: 0.5em;
}

.deliver{
  display: flex;
  align-items: center;
  margin-left: 15px;
}
.mapIcon{
  margin-top: 8px;
  height: 22px;
}
.deliverTo{
  font-size: 0.8em;
  color: rgb(187, 187, 187);
}
.deliverArea{
  font-weight: bold;
}

.search{
  display: flex;
  flex-grow: 1;
  align-items: center;
  height: 40px;
  border-radius: 24px;
  margin-left: 15px;
}
.recruitment{
  display: flex;
  height: 100%;
  align-items: center;
  padding: 0 10px 0 10px;
  background-color: #f3f3f3;
  color: black;
  font-size: 0.8em;
  border-radius: 5px 0 0 5px;
}
.triangle{
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #555;
  margin-left: 5px;
}
.inputholder{
  height: 100%;
  width: 100%;
  padding-left: 20px;
  border: none;
}
.searchIcon{
  background-color: #febd69;
  height: 100%;
  border-radius: 0 5px 5px 0;
}
.seachiconimg{
  height: 20px;
  margin: 10px;

}

.flag{
  display: flex;
  align-items: flex-end;
  margin-left: 20px;
}
.flagSvg{
  height: 25px;
}
.FlagTriangle{
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid rgb(172, 169, 169);
  margin: 0 0 5px 5px;
}

.hello{
  display: flex;
  flex-direction: column;
  margin-left: 15px;
}
.helloPra{
  font-size: 0.8em;
}
.accounts{
  display: flex;
  align-items: flex-end;
}
.accAlist{
  font-weight: bold;
  font-size: 0.9em;
}

.returnSec{
  display: flex;
  flex-direction: column;
  margin-left: 15px;
}
.returns{
  font-size: 0.8em;
}
.orders{
  font-weight: bold;
  font-size: 0.9em;
}

.cart{
  margin: 0 15px 0 15px;
  display: flex;
  align-items: flex-end;
}
.cartImg{
  height: 31px;
}

.secondNav{
  display: flex;
  align-items: center;
  background-color: #232f3e;
  color: aliceblue;
  font-size: 0.9em;
}
.hamburger{
  margin-left: 20px;
}
.ham{
  width: 22px;
  height: 2.5px;
  background-color: white;
  margin: 4px 0;
}
.categories{
  display: flex;
}
.categories > div{
  margin: 0 10px 0 10px;
}
.resumeDownload{
  margin-left: auto;
  display: flex;
  align-items: center;
}
.triangle-left { 
    height: 0;
    border-top: 20px solid transparent;
    border-right: 10px solid white;
    border-bottom: 20px solid transparent;
  z-index: 1;
}
.myskills{
  background-color: white;
  color: black;
  padding: 12px;
  font-weight: bold;
}

.navigationTree{
  font-size: 0.9em;
  margin: 15px;
  display: flex;
  align-items: center;
}
.rightArrow{
  height: 0.6em !important;
}
.navigationTree > a{
  text-decoration: none;
}