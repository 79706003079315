.reviewContent{
    display: flex;
    padding-bottom: 30px;
    border-bottom: 1px solid rgb(214, 206, 206);
    margin: 25px 15px 15px 15px;
}
.reviewContent{
    display: flex;
}
.rHeading{
    font-size: 25px;
    font-weight: bold;
}
.rTableRow > td{
    padding: 5px;
}
.rProgressBar{
    background-color: #F0F2F2;
    height: 20px;
    width: 200px;
    border-radius: 4px;
}
.trText{
    font-size: 14px;
    padding-left: 100px;
    font-weight: bold;
}
.footer{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    margin: 0px !important;
    background-color: #37475A;
    color: white;
    font-size: 14px;
}