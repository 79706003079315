.ratingHead{
    display: flex;
    font-size: 18px;
    font-weight: bold;
    align-items: center;
}
.ratingText{
    color: #565959;
    font-size: 14px;
    padding: 10px 0 10px 10px;
}
.mrStarImg{
    height: 18px;
    margin: 5px;
}
.mrTableRow > td{
    padding: 5px 5px 5px 10px;
    color: #007185;
    font-size: 14px;
}
.mrpHolder{
    display: grid;
}
.mrProgressBar{
    grid-column: 1;
    grid-row: 1;
    height: 20px;
    background-color: #F0F2F2;
    width: 200px;
    border-radius: 4px;
}

.mrProgressBarA, .mrProgressBarB, .mrProgressBarC, .mrProgressBarD, .mrProgressBarE{
    grid-column: 1;
    grid-row: 1;
    height: 20px;
    background-color: #FFA41C;
    border-radius: 4px 0 0 4px;
    border: 1px solid #c77d0e;
  }
.mrProgressBarA{
    width: 180px;
}
.mrProgressBarB{
    width: 180px;
}
.mrProgressBarC{
    width: 180px;
}
.mrProgressBarD{
    width: 140px;
}
.mrProgressBarE{
    width: 140px;
}