.experienceArea{
    display: flex;
    flex-direction: column;
    padding-bottom: 30px;
    border-bottom: 1px solid rgb(214, 206, 206);
    margin: 25px 15px 15px 15px;
}

.eaHeading{
    color: #C60;
    font-size: 16px;
    font-weight: bold;
}
.eaOrgs{
    display: flex;
}
.eaOrgName{
    font-weight: bold;
    padding: 20px 0 10px 0;
    margin-right: 15px;
    border-bottom: 1px solid rgb(214, 206, 206);
}
.eaOrgExperience{
    padding: 15px 15px 0 15px;
}
.eaoeTable{
    display: table;
    border-collapse: collapse;
    border-top: 1px solid #e7e7e7;
}
.eTableHead{
    display: table-cell;
    background-color: #f3f3f3;
    padding: 10px;
    font-weight: normal;
    font-size: 14px;
    text-align: left;
}
.eTableText{
    font-size: 14px;
    padding: 10px 5px 10px 15px;
}
.eaoeTable > tbody > tr{
    border-bottom: 1px solid #e7e7e7;
}